<template>
	<w-flex v-if="showSettings" shrink>
		<w-btn @click="drawer = true">{{ $t('notifications.profile.advanced_settings') }}</w-btn>
		<w-navigation-drawer v-model="drawer" absolute default-width="800px" max-width="100vw" right temporary>
			<w-section :title="$t('notifications.profile.advanced_settings')">
				<w-layout row wrap>
					<w-flex xs6>
						<w-text-input v-model="searchedText" :label="$t('actions.search')" prepend-icon="search" />
					</w-flex>
				</w-layout>
				<w-matrix
					:headers="headers"
					:loading="loading"
					:no-data-text="
						isAccountant
							? $t('notifications.profile.no_customer_found', { search: searchedText })
							: $t('notifications.profile.no_company_found', { search: searchedText })
					"
					:pagination.sync="pagination"
					:total-items="totalCompanies"
					:value="companies"
				>
					<template v-for="(family, index) in families" v-slot:[getHeaderSlotName(index)]>
						<th :key="family.id">
							<v-layout align-center column justify-center>
								<w-checkbox
									hide-details
									:indeterminate="family.is_active === null"
									:ripple="false"
									style="width: 24px"
									:value="family.is_active"
									@input="toggleFamily(family, $event)"
								/>
								<v-flex text-xs-center v-text="family.name" />
							</v-layout>
						</th>
					</template>
					<template v-slot:items="{ row }">
						<td class="text-xs-left">
							<v-layout align-center justify-start row>
								<w-flex mr-2 shrink>
									<w-checkbox
										:key="row.id"
										color="secondary"
										:indeterminate="row.is_active === null"
										:ripple="false"
										style="width: 24px"
										:value="row.is_active"
										@input="toggleCompanyFamilies(row, $event)"
									/>
								</w-flex>
								<v-flex shrink v-text="row.name" />
							</v-layout>
						</td>
						<td v-for="family in row.families" :key="family.id" class="text-xs-center">
							<w-layout align-center column justify-center>
								<w-checkbox
									:key="`${row.id}-${family.id}`"
									:ripple="false"
									style="width: 24px"
									:value="family.is_active"
									@input="toggleFamilyOfCompany(row, family, $event)"
								/>
							</w-layout>
						</td>
					</template>
				</w-matrix>
			</w-section>
		</w-navigation-drawer>
	</w-flex>
</template>

<script>
import { mapState } from 'vuex'
import NotificationsModuleGuard from '@/mixins/ModulesGuards/Notifications/NotificationsModuleGuard'

export default {
	name: 'AdvancedSettings',
	mixins: [NotificationsModuleGuard],
	data: function () {
		return {
			companies: [],
			drawer: false,
			loading: false,
			pagination: {
				page: 1,
				rowsPerPage: 15
			},
			searchedText: '',
			showSettings: false,
			totalCompanies: 0
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			families: state => state.notifications.families
		}),
		headers: function () {
			let result = [{ sortable: false, text: this.isAccountant ? this.$t('notifications.profile.customers') : this.$t('notifications.profile.companies') }]
			this.families.forEach(family => {
				result.push({
					sortable: false,
					text: family.name
				})
			})
			return result
		}
	},
	watch: {
		searchedText: {
			handler: function () {
				this.listCompanies(true)
			}
		},
		pagination: {
			deep: true,
			handler: function (newVal, oldVal) {
				if ((!oldVal && newVal) || newVal.page !== oldVal.page || newVal.rowsPerPage !== oldVal.rowsPerPage) {
					this.listCompanies()
				}
			},
			immediate: true
		}
	},
	created: function () {
		this.listCompanies(true)
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [{ event: this.events.FAMILY_UPDATED, action: this.onFamilyUpdated }]
		},
		getHeaderSlotName: function (index) {
			return `header-cell-${index + 1}`
		},
		listCompanies: async function (reset = false) {
			this.loading = true
			if (reset) {
				this.companies.clear()
				this.pagination = {
					rowsPerPage: 15,
					page: 1
				}
				this.totalUsers = 0
			}

			try {
				const data = await this.service.listCompanies(this.pagination, this.searchedText)
				this.companies = data.data
				this.totalCompanies = data.total
			} finally {
				this.loading = false
				if (reset && !this.searchedText) {
					this.showSettings = this.totalCompanies > 1
				}
			}
		},
		onFamilyUpdated: function (family) {
			if (family.is_active === null) {
				return;
			}

			this.companies = this.companies.map(company => {
				company.families = company.families.map(f => {
					if (f.id === family.id) {
						f.is_active = family.is_active
					}
					return f
				})
				const isAllActive = company.families.every(({ is_active }) => is_active)
				const someActive = company.families.some(({ is_active }) => is_active)
				company.is_active = isAllActive ? true : someActive ? null : false
				return company
			})
		},
		toggleCompanyFamilies: async function (row, toggleValue) {
			const vendorId = row.id
			const company = await this.service.toggleCompanyFamilies(vendorId, toggleValue)
			this.updateCompanies(company)
		},
		toggleFamily: function (family, toggleValue) {
			const familyId = family.id
			this.service.toggleFamily(familyId, toggleValue)
		},
		toggleFamilyOfCompany: async function (row, family, toggleValue) {
			const vendorId = row.id
			const familyId = family.id
 			const company = await this.service.toggleFamilyOfCompany(familyId, vendorId, toggleValue)
			this.updateCompanies(company)
		},
		updateCompanies: function (company) {
			const index = this.companies.findIndex(c => c.id === company.id)
			if (index === -1) {
				return
			}

			this.companies[index] = company
			this.companies = [...this.companies]
		}
	}
}
</script>
